<template>
  <div class='legal-container' >
    <div style="padding-bottom: 120px;">
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer'

export default {
  name: 'Legal',
  methods: {},
  components: { Footer },
  computed: {
    backgroundImage () {
      return process.env.BASE_URL + 'media/bg/bg-2.jpg'
    }
  }
}
</script>

<style lang="scss" scoped>
.legal-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  // width: calc(100% - 600px);
  width: 100%;

  // background-image: url('/media/bg/login.jpg');
  background-repeat: no-repeat;
  background-color: $primary-dark-2;
  background-size: 100%;

  color: white;
}
</style>
