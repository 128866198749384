<template>
  <footer class="text-color-text-primary">
    <div class="flex footer-content-wrapper">
      <div class="logo-wrapper" style="">
        <img src="/media/logos/a_telefonica_company.png" alt="" style="height: 25px">
      </div>
      <div class="footer-content">
        {{ $t('FOOTER.content', { year: currentYear }) }}
        <router-link :to="{ name: 'privacy' }" class="text-white" target="_blank" rel="noreferrer">{{ $t('LEGAL.USE_POLICY.title') }}</router-link> |
        <router-link :to="{ name: 'privacy', hash: '#privacy' }" class="text-white" target="_blank" rel="noreferrer">{{ $t('LEGAL.PRIVACY.title') }}</router-link> |
        <router-link :to="{ name: 'privacy', hash: '#cookies' }" class="text-white" target="_blank" rel="noreferrer">{{ $t('LEGAL.COOKIE_POLICY.title') }}</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear: () => (new Date()).getFullYear()
  }
}
</script>

<style lang="scss" scoped>
footer {
  // color: white;
  padding: 40px;
  font-size: 0.75rem;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: white;
  color: #767676;

  a {
    color: $blue !important;
    text-transform: uppercase;
    text-decoration: none;

    &:visited, &:active {
      color: $blue !important;
    }

    &:hover {
      color: $pink !important;
    }
  }

  .footer-content {
    flex: 1;
    text-align: center;
  }

  .logo-wrapper {
    width: 25%;
    text-align: center;
  }
}

@media (max-height: 700px) {
  #kt_login{
    flex: 1;
    padding-bottom: 64px !important;
  }

  footer {
    position: relative;
  }
}

@media (max-width: $mobile-breakpoint) {
  footer {
    position: relative;

    .footer-content-wrapper {
      flex-direction: column;
    }

    .footer-content {
      width: 100%;
      text-align: center;
      font-size: 0.75rem;
      padding: 0px 2%;
      margin-top: 16px;
    }

    .social-container {
      width: 100%;
      margin-top: 0.5rem;
      text-align: center;
    }

    .logo-wrapper {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
